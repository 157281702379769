<template>
  <!--begin::ContactForm-->
  <div
    class="row pb-12"
    style="background-color: #010B2D; background-image: url('media/landing/contact/curva.jpg'); background-size: contain; background-repeat: no-repeat"
  >
    <div class="col-lg-3">
      &nbsp;
    </div>
    <div class="col-lg-6 contact-form text-center">
      <v-form ref="formContact" class="form" v-model="Valid" lazy-validation>
        <div class="h2-primary">
          {{ $t("LANDING.CONTACT.FORM.TITLE") }}
          <div class="tertiary-rectangle" style="margin: 0.75em auto;"></div>
        </div>

        <div class="paragraph-primary mb-6">
          {{ $t("LANDING.CONTACT.FORM.PARAGRAPH") }}
        </div>

        <div class="form-group">
          <v-text-field
            class="form-control"
            v-model="Fields.Fullname"
            :placeholder="$t('LANDING.CONTACT.FORM.FULLNAME')"
            autocomplete="off"
            :rules="[rules.required]"
            required
          />
        </div>

        <div class="form-group">
          <v-text-field
            class="form-control"
            v-model="Fields.Phone"
            :placeholder="$t('LANDING.CONTACT.FORM.PHONE')"
            autocomplete="off"
            :rules="[rules.required]"
            required
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/phone-gray.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="form-group">
          <v-text-field
            class="form-control"
            v-model="Fields.Email"
            :placeholder="$t('LANDING.CONTACT.FORM.EMAIL')"
            autocomplete="off"
            :rules="[rules.required, rules.email]"
            required
          >
            <template v-slot:prepend-inner>
              <inline-svg src="/media/icons/contact-gray.svg" />
            </template>
          </v-text-field>
        </div>

        <div class="form-group">
          <v-text-field
            class="form-control"
            v-model="Fields.Reference"
            :placeholder="$t('LANDING.CONTACT.FORM.REFERENCE')"
            autocomplete="off"
          />
        </div>

        <div class="form-group">
          <textarea
            rows="3"
            class="form-control"
            v-model="Fields.Content"
            :placeholder="$t('LANDING.CONTACT.FORM.MESSAGE')"
            :rules="[rules.required]"
            required
          ></textarea>
          <div
            v-if="!contentValid"
            class="v-text-field__details pt-2 pl-5 pb-0 m-0"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message text-left">
                  {{ $t("VALIDATION.REQUIRED_FIELD") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pt-6 pb-3 text-center">
          <button
            ref="formForgotSubmit"
            class="btn btn-pill btn-tertiary"
            :disabled="!Valid || !contentValid"
            @click="onSubmitContactEmail"
          >
            {{ $t("LANDING.CONTACT.FORM.SEND") }}
          </button>
        </div>
      </v-form>
    </div>
    <div class="col-lg-3">
      &nbsp;
    </div>
  </div>
  <!--end::ContactForm-->
</template>

<script>
import ContacEmailService from "@/core/services/api/v2/contactemail.service";

export default {
  name: "LandingContactForm",
  data() {
    return {
      Fields: {
        Fullname: "",
        Phone: "",
        Email: "",
        Reference: "",
        Content: ""
      },
      Valid: true,
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL")
      }
    };
  },

  computed: {
    contentValid() {
      return this.Fields.Content.length > 0;
    }
  },

  mounted() {
    // Force "required field" messages
    this.$nextTick(() => {
      this.$refs.formContact.validate();
    });
  },

  methods: {
    onSubmitContactEmail(e) {
      e.preventDefault();

      if (this.$refs.formContact.validate() && this.contentValid) {
        // Disable submit button
        this.Valid = false;

        // Send request
        ContacEmailService.send(this.Fields).then(statusCode => {
          if (statusCode < 400) {
            this.$bvToast.toast(
              this.$i18n.t("LANDING.CONTACT.FORM.SEND_OK_2"),
              {
                title: this.$i18n.t("LANDING.CONTACT.FORM.SEND_OK_1"),
                variant: "success",
                noCloseButton: true,
                solid: true
              }
            );
          } else {
            this.$bvToast.toast(this.$i18n.t("LANDING.CONTACT.FORM.SEND_KO"), {
              title: this.$i18n.t("GENERIC.ERROR"),
              variant: "danger",
              noCloseButton: true,
              solid: true
            });
          }

          // Reset form
          this.Valid = false;
          this.Fields.Fullname = "";
          this.Fields.Phone = "";
          this.Fields.Email = "";
          this.Fields.Reference = "";
          this.Fields.Content = "";
        });
      }
    }
  }
};
</script>
