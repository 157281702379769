<template>
  <!--begin::Landing-->
  <div>
    <!--begin::Catchphrase-->
    <div
      class="block1 row"
      style="background-image: url('media/landing/index/fondo.jpg');"
    >
      <div class="col-lg-8 overlay-primary py-12 px-24">
        <div class="h1-white">
          {{ $t("LANDING.INDEX.BLOCK1.TITLE") }}
          <div class="tertiary-rectangle mt-3"></div>
        </div>

        <div class="paragraph-white mt-6 mb-12">
          {{ $t("LANDING.INDEX.BLOCK1.PARAGRAPH") }}
        </div>

        <div
          v-if="
            !isAuthenticated ||
              loggedUserRoleID === rolesList.ID_SENDER_ADMIN ||
              loggedUserRoleID === rolesList.ID_SENDER_EMPLOYEE
          "
          class="mb-6"
        >
          <button
            class="btn btn-pill button-form btn-tertiary mt-6"
            @click="startNewPriceSearch"
          >
            <inline-svg src="/media/icons/search-alt-1.svg" class="mr-3" />
            {{ $t("LANDING.INDEX.BLOCK1.BUTTON") }}
          </button>
        </div>

        <inline-svg
          src="/media/landing/index/arrow-down.svg"
          class="cursor-pointer"
          style="margin-left: 49vw; width: 2.5em;"
          @click="$vuetify.goTo('.block3', { offset: 60 })"
        />
      </div>
    </div>
    <!--end::Catchphrase-->

    <!--begin::Special-->
    <div
      v-if="
        SpecialShipmentsAreDone &&
          (!isAuthenticated ||
            loggedUserRoleID === rolesList.ID_SENDER_ADMIN ||
            loggedUserRoleID === rolesList.ID_SENDER_EMPLOYEE)
      "
      class="block2 row"
    >
      <div class="col-lg-12">
        <div class="h2-primary mb-9">
          {{ $t("LANDING.INDEX.BLOCK2.TITLE") }}
          <div class="tertiary-rectangle" style="margin: 0.75em auto;"></div>
        </div>

        <div class="paragraph-primary mb-9">
          {{ $t("LANDING.INDEX.BLOCK2.PARAGRAPH") }}
        </div>

        <div class="d-flex justify-content-around">
          <div>
            <inline-svg
              src="/media/landing/index/barco.svg"
              class="block2-icon"
            />
            <div>
              {{ $t("LANDING.INDEX.BLOCK2.SHIP") }}
            </div>
          </div>
          <div>
            <inline-svg
              src="/media/landing/index/camion-confidencial.svg"
              class="block2-icon"
            />
            <div>
              {{ $t("LANDING.INDEX.BLOCK2.CONFIDENTIAL") }}
            </div>
          </div>
          <div>
            <inline-svg
              src="/media/landing/index/camion-grupaje.svg"
              class="block2-icon"
            />
            <div>
              {{ $t("LANDING.INDEX.BLOCK2.GROUP") }}
            </div>
          </div>
          <div>
            <inline-svg
              src="/media/landing/index/grua.svg"
              class="block2-icon"
            />
            <div>
              {{ $t("LANDING.INDEX.BLOCK2.OPEN") }}
            </div>
          </div>
          <div>
            <inline-svg
              src="/media/landing/index/driver.svg"
              class="block2-icon"
            />
            <div>
              {{ $t("LANDING.INDEX.BLOCK2.DRIVER") }}
            </div>
          </div>
          <div>
            <inline-svg
              src="/media/landing/index/moto.svg"
              class="block2-icon"
            />
            <div>
              {{ $t("LANDING.INDEX.BLOCK2.MOTORCYCLES") }}
            </div>
          </div>
          <div>
            <inline-svg
              src="/media/landing/index/excavadora.svg"
              class="block2-icon"
            />
            <div>
              {{ $t("LANDING.INDEX.BLOCK2.HEAVY") }}
            </div>
          </div>
          <div>
            <inline-svg
              src="/media/landing/index/furgo.svg"
              class="block2-icon"
            />
            <div>
              {{ $t("LANDING.INDEX.BLOCK2.HIGH") }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 text-center py-12">
        <button class="btn btn-pill button-form btn-tertiary px-12">
          {{ $t("LANDING.INDEX.BLOCK2.BUTTON") }}
        </button>
      </div>
    </div>
    <!--end::Special-->

    <!--begin::How it works-->
    <div
      class="block3 row"
      style="background-image: url('media/landing/index/como-funciona.svg');"
    >
      <div class="col-lg-6">
        <div class="h1-white">
          {{ $t("LANDING.INDEX.BLOCK3.TITLE") }}
          <div class="tertiary-rectangle mt-3"></div>
        </div>

        <div class="paragraph-white mt-6 mb-9">
          {{ $t("LANDING.INDEX.BLOCK3.PARAGRAPH1") }}
          <br />
          {{ $t("LANDING.INDEX.BLOCK3.PARAGRAPH2") }}
        </div>
      </div>
    </div>
    <!--end::How it works-->

    <!--begin::Howto-->
    <div class="block4">
      <!--begin::Howto Navigator-->
      <div class="row">
        <div
          class="col-lg-3 block4-tag"
          @click="$vuetify.goTo('.block4-step1', { offset: 50 })"
        >
          {{ $t("LANDING.INDEX.BLOCK4.STEP1.TAG") }}
        </div>
        <div
          class="col-lg-3 block4-tag"
          @click="$vuetify.goTo('.block4-step2', { offset: 50 })"
        >
          {{ $t("LANDING.INDEX.BLOCK4.STEP2.TAG") }}
        </div>
        <div
          class="col-lg-3 block4-tag"
          @click="$vuetify.goTo('.block4-step3', { offset: 50 })"
        >
          {{ $t("LANDING.INDEX.BLOCK4.STEP3.TAG") }}
        </div>
        <div class="col-lg-3 block4-tag">
          &nbsp;
        </div>
      </div>
      <!--end::Howto Navigator-->

      <!--begin::Howto Step1-->
      <div class="row align-items-center block4-step1">
        <div class="col-lg-6 text-center py-0">
          <inline-svg
            src="/media/landing/index/group-1.svg"
            class="block4-step"
          />
        </div>
        <div class="col-lg-6">
          <div class="h3-primary">
            {{ $t("LANDING.INDEX.BLOCK4.STEP1.TITLE") }}
          </div>

          <div class="paragraph-primary mt-6">
            {{ $t("LANDING.INDEX.BLOCK4.STEP1.PARAGRAPH1") }}
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.INDEX.BLOCK4.STEP1.PARAGRAPH2") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.INDEX.BLOCK4.STEP1.PARAGRAPH3") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.INDEX.BLOCK4.STEP1.PARAGRAPH4") }}</span>
          </div>
        </div>
      </div>
      <!--end::Howto Step1-->

      <!--begin::Howto Step2-->
      <div class="row align-items-center block4-step2">
        <div class="col-lg-6">
          <div class="h3-primary">
            {{ $t("LANDING.INDEX.BLOCK4.STEP2.TITLE") }}
          </div>

          <div class="paragraph-primary mt-6">
            {{ $t("LANDING.INDEX.BLOCK4.STEP2.PARAGRAPH1") }}
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.INDEX.BLOCK4.STEP2.PARAGRAPH2") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.INDEX.BLOCK4.STEP2.PARAGRAPH3") }}</span>
          </div>
        </div>
        <div class="col-lg-6 text-center py-0">
          <inline-svg
            src="/media/landing/index/group-2.svg"
            class="block4-step"
          />
        </div>
      </div>
      <!--end::Howto Step2-->

      <!--begin::Howto Step3-->
      <div class="row align-items-center block4-step3">
        <div class="col-lg-6 text-center py-0">
          <inline-svg
            src="/media/landing/index/group-3.svg"
            class="block4-step"
          />
        </div>
        <div class="col-lg-6">
          <div class="h3-primary">
            {{ $t("LANDING.INDEX.BLOCK4.STEP3.TITLE") }}
          </div>

          <div class="paragraph-primary mt-6">
            {{ $t("LANDING.INDEX.BLOCK4.STEP3.PARAGRAPH1") }}
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.INDEX.BLOCK4.STEP3.PARAGRAPH2") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.INDEX.BLOCK4.STEP3.PARAGRAPH3") }}</span>
          </div>
          <div class="paragraph-primary my-3 d-flex">
            <div class="tick-icon">
              <inline-svg src="/media/landing/index/validation-check_2.svg" />
            </div>
            <span>{{ $t("LANDING.INDEX.BLOCK4.STEP3.PARAGRAPH4") }}</span>
          </div>
        </div>
      </div>
      <!--end::Howto Step3-->
    </div>
    <!--end::Howto-->

    <!--begin::Why-->
    <div class="block5a row">
      <div class="col-lg-12">
        <div class="h1-white">
          {{ $t("LANDING.INDEX.BLOCK5.TITLE") }}
          <div class="tertiary-rectangle" style="margin: 0.75em auto;"></div>
        </div>
      </div>
    </div>
    <div
      class="block5b row px-12"
      style="background-image: url('media/landing/index/bg-primary.png');"
    >
      <div class="col-lg-3 px-6">
        <inline-svg
          src="/media/landing/index/flexibilidad.svg"
          class="block5-icon"
        />
        <div class="paragraph-primary mt-3 mb-1">
          <strong>{{ $t("LANDING.INDEX.BLOCK5.STEP1.TITLE") }}</strong>
        </div>
        <div class="paragraph-primary">
          {{ $t("LANDING.INDEX.BLOCK5.STEP1.PARAGRAPH") }}
        </div>
      </div>
      <div class="col-lg-3 px-6">
        <inline-svg src="/media/landing/index/seguro.svg" class="block5-icon" />
        <div class="paragraph-primary mt-3 mb-1">
          <strong>{{ $t("LANDING.INDEX.BLOCK5.STEP2.TITLE") }}</strong>
        </div>
        <div class="paragraph-primary">
          {{ $t("LANDING.INDEX.BLOCK5.STEP2.PARAGRAPH") }}
        </div>
      </div>
      <div class="col-lg-3 px-6">
        <inline-svg src="/media/landing/index/plazos.svg" class="block5-icon" />
        <div class="paragraph-primary mt-3 mb-1">
          <strong>{{ $t("LANDING.INDEX.BLOCK5.STEP3.TITLE") }}</strong>
        </div>
        <div class="paragraph-primary">
          {{ $t("LANDING.INDEX.BLOCK5.STEP3.PARAGRAPH") }}
        </div>
      </div>
      <div class="col-lg-3 px-6">
        <inline-svg
          src="/media/landing/index/comunicacion.svg"
          class="block5-icon"
        />
        <div class="paragraph-primary mt-3 mb-1">
          <strong>{{ $t("LANDING.INDEX.BLOCK5.STEP4.TITLE") }}</strong>
        </div>
        <div class="paragraph-primary">
          {{ $t("LANDING.INDEX.BLOCK5.STEP4.PARAGRAPH") }}
        </div>
      </div>
    </div>
    <!--end::Why-->

    <LandingContactForm class="mt-18" />
  </div>
  <!--end::Landing-->
</template>

<script>
import { mapGetters } from "vuex";

import LandingContactForm from "@/view/pages/landing/ContactForm.vue";
import PriceSearchService from "@/core/services/api/v2/pricesearch.service";

export default {
  name: "LandingIndex",

  components: {
    LandingContactForm
  },

  computed: {
    ...mapGetters(["isAuthenticated", "currentUserRoleID", "rolesList"])
  },

  data() {
    return {
      SpecialShipmentsAreDone: false,
      loggedUserRoleID: 0
    };
  },

  mounted() {
    this.$nextTick(() => {
      document.title = this.$t("HEADER.TITLE");
    });

    // Set content by role
    this.loggedUserRoleID = this.currentUserRoleID;

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setCompany") {
        this.loggedUserRoleID = state.auth.user.Company.RoleID;
      }
    });
  },

  methods: {
    startNewPriceSearch() {
      PriceSearchService.cleanCachedLists();
      this.$router.push("/pricesearch");
    }
  }
};
</script>
