import ApiService from "@/core/services/api/v2/api.service";

const ContacEmailService = {
  send(contactData) {
    return new Promise(resolve => {
      ApiService.post("contactEmail", contactData)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default ContacEmailService;
