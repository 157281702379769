import ApiService from "@/core/services/api/v2/api.service";

const PRICE_SEARCH_ROUTES = "PriceSearchRoutes";
const PRICE_SEARCH_RESULTS = "PriceSearchResults";

const PriceSearchService = {
  queryPrices(routesData) {
    // For future reference, we store the huge RoutesData, and the results
    window.sessionStorage.setItem(
      PRICE_SEARCH_ROUTES,
      JSON.stringify(routesData)
    );

    window.sessionStorage.removeItem(PRICE_SEARCH_RESULTS);

    return new Promise(resolve => {
      ApiService.put("priceSearch/search", routesData)
        .then(response => {
          window.sessionStorage.setItem(
            PRICE_SEARCH_RESULTS,
            JSON.stringify(response.data.response)
          );
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getCachedRoutes() {
    let cachedRoutes = window.sessionStorage.getItem(PRICE_SEARCH_ROUTES);
    if (cachedRoutes) {
      return JSON.parse(cachedRoutes);
    } else {
      return { Routes: [] };
    }
  },

  getCachedResults() {
    let cachedResults = window.sessionStorage.getItem(PRICE_SEARCH_RESULTS);
    if (cachedResults) {
      return JSON.parse(cachedResults);
    } else {
      return { Routes: [] };
    }
  },

  cleanCachedRoutes() {
    window.sessionStorage.removeItem(PRICE_SEARCH_ROUTES);
  },

  cleanCachedResults() {
    window.sessionStorage.removeItem(PRICE_SEARCH_RESULTS);
  },

  cleanCachedLists() {
    this.cleanCachedRoutes();
    this.cleanCachedResults();
  },

  register(registerData) {
    return new Promise(resolve => {
      ApiService.post("priceSearch/register", registerData)
        .then(response => {
          // Return full response data, because it holds login info
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  purchase(routeIndex, transporterID, purchaseData) {
    // Append basic search-route-transporter info
    purchaseData.SearchUUID = this.getCachedResults()["SearchUUID"];
    purchaseData.RouteIndex = routeIndex;
    purchaseData.TransporterCompanyID = transporterID;

    return new Promise(resolve => {
      ApiService.put("priceSearch/purchase", purchaseData)
        .then(response => {
          // Return full response data, because it may hold payment info
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default PriceSearchService;
